import { useMutation } from "@apollo/client";
import classNames from "classnames";
import { debounce } from "lodash";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import client from "../../behavior/apolloClient";
import { useAppSelector } from "../../behavior/hooks";
import { SET_VIEWER_LANGUAGE } from "../../behavior/queries/viewer.query";
import { ApplicationLanguage } from "../../constants";
import { deleteCookie, setCookie } from "../../utils/cookieHandlers";
import { Text } from "../elements";

interface LanguageSelectorProps {
	isMobile?: boolean;
	language?: string;
}

const options = [
	{ value: ApplicationLanguage.EN_EU, label: "English" },
	{ value: ApplicationLanguage.SV_SE, label: "Swedish" },
	{ value: ApplicationLanguage.DE_DE, label: "German" },
];

const LanguageSelector: FC<LanguageSelectorProps> = ({
	isMobile,
	language,
}) => {
	const { i18n } = useTranslation();
	const { isLogged } = useAppSelector((state) => state.session);
	const [setViewerLanguage] = useMutation(SET_VIEWER_LANGUAGE);
	const [selectedOption, setSelectedOption] = useState(
		language || i18n.language || ApplicationLanguage.EN_EU
	);

	const setLanguageDebounced = debounce((lang) => {
		setViewerLanguage({ variables: { language: lang } });
	}, 1000);

	useEffect(() => {
		if (language) {
			setSelectedOption(language);
		}
	}, [language]);

	useEffect(() => {
		setSelectedOption(i18n.language);
	}, [i18n.language]);

	const changeLanguage = (value: string) => {
		if (selectedOption === value) {
			return;
		}

		setSelectedOption(value);
		setCookie("language", value);
		i18n.changeLanguage(value);
		if (isLogged) {
			setCookie("preferred-language", value);
			setLanguageDebounced(value);
			client.refetchQueries({
				include: "active",
			});
		} else {
			deleteCookie("preferred-language");
		}
	};

	return (
		<div className={classNames(isMobile && "language-mobile-wrapper")}>
			{isMobile && (
				<Text size="large" weight="light" block className="mb-3">
					Language
				</Text>
			)}
			<Select
				className="language-selector"
				classNamePrefix="custom"
				options={options}
				components={{ IndicatorSeparator: () => null }}
				isSearchable={false}
				value={options.filter((item) => item.value === selectedOption)}
				onChange={(option) =>
					option && option.value && changeLanguage(option.value)
				}
			/>
		</div>
	);
};

LanguageSelector.defaultProps = {
	isMobile: false,
	language: undefined,
};

export default LanguageSelector;
